import React, { Dispatch, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import TextInput from "../../common/components/TextInput";
import Loader from "../../common/components/loader";
import { setDemoUse5 } from "../../store/actions/reseller.action";
import { IResellerState } from "../../store/models/reseller.interface";
import { getDemoUseDetailsByToken, getDemoUseAccuracy, getResellerAccountSetUpDetail } from "../../store/actions/reseller.action";
import { getIp } from '../../store/actions/campaign.actions';
import { ThunkDispatch } from "redux-thunk"

import { AnyAction } from "redux";
type State = { a: string };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;
const DemoUse5 = () => {
    const { demoToken }: any = useParams();
    const [selectedProspect, setselectedProspect] = useState<{ value: string, label: string }>()
    const demoDetialByToken = useSelector((state: any) => state.reseller.demoDetialByToken);

    const resellerAccountData = useSelector((state: IResellerState) => state?.reseller?.resellerAccountData);
    const accuracyList = useSelector((state: any) => state.reseller.accuracyList);

    const dispatch: AppDispatch = useDispatch()
    const loader: any = useSelector((state: IResellerState) => state.reseller.isLoader);
    const [formState, setFormState] = useState({
        firstName: { error: "", value: "" },
        email: { error: "", value: "" },
        phone: { error: "", value: "" },
        accuracy: { error: "", value: { label: "", value: 0 } },
    });

    const accuracySelect: { value: any; label: any; }[] = [];
    accuracyList && accuracyList?.length > 0 && accuracyList.forEach((item: { gradeDemo_Ind: any; descr: any; }) => {
        accuracySelect.push({ value: item.gradeDemo_Ind, label: item.descr });
    });

    const [isSuccess, setSuccess] = useState(false);
    // const accuracySelect = [
    //     { value: 1, label: 'Lacks any accuracy' },
    //     { value: 2, label: 'Accurate in parts' },
    //     { value: 3, label: 'A balance of accuracy and inaccuracies' },
    //     { value: 4, label: 'Mostly accurate' },
    //     { value: 5, label: 'Extremely accurate' }
    // ]

    useEffect(() => {
        dispatch(getIp());
        dispatch(getResellerAccountSetUpDetail());
        dispatch(getDemoUseAccuracy()).then((respoAcc: any) => {
            dispatch(getDemoUseDetailsByToken(demoToken)).then((res: any): any => {
                if (res) {
                    const { fullName = "", contactEmail = null, telNo = null, accuracy = 0 } = res
                    let selectedAcc = respoAcc.find((item: any) => item.gradeDemo_Ind == accuracy)
                    if (accuracy > 0) {
                        setSuccess(true);
                    }
                    setFormState({
                        firstName: { error: "", value: fullName },
                        email: { error: "", value: contactEmail },
                        phone: { error: "", value: telNo },
                        accuracy: {
                            error: "", value: { label: selectedAcc?.descr, value: selectedAcc?.gradeDemo_Ind }
                        }
                    })
                }
            });
        });

    }, [dispatch, demoToken]);

    function hasFormValueChanged(model: any): void {
        if (model.field === 'firstName' && model.value.split(" ").length > 2) {
            model.value = model.value.replace(/ +/g, ' ').trim();
        }
        setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } })
        // dispatch(setUserData({ ...wizardUserData, [model.field]: model.value }))
    }

    const handleChangeProspect = (e: any) => {
        setselectedProspect({ value: e.value, label: e.label });
        setFormState({ ...formState, accuracy: { error: e.error, value: { value: e.value, label: e.label } } })
    };


    const handleSubmitDemo = (e: any) => {
        const data = {
            FullName: formState.firstName.value,
            ContactEmail: formState.email.value,
            TelNo: formState.phone.value,
            Accuracy: formState.accuracy.value?.value,
            demoToken: demoToken,
        }
        dispatch(setDemoUse5(data)).then(response => {
            if (response && response.data > 0) {
                setSuccess(true)
            }
        })
    }

    if (loader) {
        return (
            <Loader children={''} isLoading={loader} type={"circle"} />
        );
    }
    return (
        <>
            <div className="container-fluid">
                <div className="logo-bottom-demonstration">
                    <img src={resellerAccountData?.headerLogoFile_PathName} alt={resellerAccountData?.tradingName} />
                    <h2 style={{color:resellerAccountData?.hexColor}}>{resellerAccountData?.tradingName}</h2>
                </div>
                <div className="col-lg-9 m-auto col-md-12 col-sm-12 create-campaign-main border-bottom">
                    <div className="demonstration-heading mb-4">
                        <h2>Demonstrator</h2>
                        <h4>Grade Snapshot Report {isSuccess && "Complete"} </h4>
                    </div>
                    {!isSuccess ?
                        <>
                            <div className="row">
                                <div className="col-lg-3 col-md-12 col-sm-12 demonstrationSetUp-left">
                                    <hr />
                                    <h5>Personal Details</h5>
                                    <p>please enter your personal details</p>
                                </div>
                                <div className="col-lg-9 col-md-12 col-sm-12 demonstrationSetUp-right">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <TextInput
                                                    id="firstName"
                                                    type="text"
                                                    field="firstName"
                                                    value={formState.firstName.value}
                                                    onChange={hasFormValueChanged}
                                                    required={true}
                                                    isFullname={true}
                                                    maxLength={100}
                                                    label="Full Name*"
                                                    placeholder=""
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <TextInput
                                                    id="email"
                                                    type="text"
                                                    field="email"
                                                    value={formState.email.value}
                                                    onChange={hasFormValueChanged}
                                                    required={true}
                                                    maxLength={100}
                                                    label="Email*"
                                                    placeholder=""
                                                    isEmail={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <TextInput
                                                    id="phone"
                                                    type="text"
                                                    field="phone"
                                                    value={formState.phone.value}
                                                    onChange={hasFormValueChanged}
                                                    required={false}
                                                    maxLength={100}
                                                    label="Phone"
                                                    placeholder=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="demonstration-divider"></div>
                            <div className="row ">
                                <div className="col-lg-3 col-md-12 col-sm-12 demonstrationSetUp-left">
                                    <hr />
                                    <h5>Accuracy</h5>
                                    <p>Please select from the dropdown list</p>
                                </div>
                                <div className="col-lg-9 col-md-12 col-sm-12 demonstrationSetUp-right">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <Select
                                                    id="accuracy"
                                                    field="accuracy"
                                                    className="search-input"
                                                    type="select"
                                                    value={formState.accuracy.value && formState.accuracy.value}
                                                    onChange={handleChangeProspect}
                                                    options={accuracySelect}
                                                    required={true}
                                                    // menuIsOpen={true}
                                                    label="Expiry Days"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-5">
                                <Button variant="primary" className="crate-btn-modal"
                                    disabled={
                                        !formState.firstName.value ||
                                        !formState.email.value ||
                                        !formState.accuracy.value
                                    }
                                    onClick={handleSubmitDemo}>
                                    SUBMIT
                                </Button>
                            </div>
                        </> :
                        <>
                            <div className="demonstration-allocate-none">
                                <p>Thank you {demoDetialByToken?.contactFname} for grading the accuracy of your Snapshot.</p>
                                {/* <p>
                                    We have sent an email to  {demoDetialByToken?.demoAcc_Allocator} informing him/her that(s)/he may now allocate another Taste Test.
                                </p> */}

                            </div>
                        </>
                    }

                </div>
            </div>
        </>
    );
};

export default DemoUse5;
