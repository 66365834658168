import React, { useState, useEffect, useRef } from "react";
import frame from "../../assets/shape-recognition-frame1.png";
import redframe from "../../assets/camera-red1.png";
import greenframe from "../../assets/camera-green1.png";
import avatar from "../../assets/avtarDemo.jpg";
import Webcam from "react-webcam";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { uploadToBfm, setCompleted, cameraSelfieError } from '../../store/actions/campaign.actions';
import shutterSound from '../../assets/camera-shutter-click.mp3';
import ApplicationProblem from "./applicationProblem";
import * as faceapi from 'face-api.js';
import * as facemesh from "@tensorflow-models/face-landmarks-detection";
import { drawMesh } from "../../utilities";

const useAudio = url => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);


    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    },
        [playing]
    );

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);
    return [playing, toggle];
};
let shots = [];
const ProfileCapture = ({ setStep, step }) => {

    const [stop, setStop] = useState(false);


    const demoDetialByToken = useSelector((state) => state.reseller.demoDetialByToken);
    const resellerAccountData = useSelector((state) => state?.reseller?.resellerAccountData);

    const { demoToken } = useParams();
    const dispatch = useDispatch();
    const [capture, setCapture] = useState(false);
    const [showCamera, setShowCamera] = useState(false);
    const [percentage, setPercentage] = useState(null);

    const isCompleted = useSelector((state) => state.campaign.completed);
    const errorUploading = useSelector((state) => state.campaign.error)
    const [playing, toggle] = useAudio(shutterSound);

    // const toggle = () => setPlaying(!playing);
    const [detectionsValue, setdetectionsValue] = useState([]);

    const [imageAngle, setImageAngle] = useState({ pitchDegree: 0, rollDegree: 0, yawDegree: 0 });


    const webcamRef = useRef(null);
    const canvasRef = React.useRef(null);
    const [error, setError] = useState("");

    const cameraReady = () => {
        setShowCamera(true);
        setCapture(true);
        // runFacemesh();
    }

    const b64toBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    }

    const capture1 = async (angle, box, neutral) => {
        const imageSrc = webcamRef.current.getScreenshot();
        shots.push({ image: b64toBlob(imageSrc) });
        console.log(angle, 'imageAngleimageAngle', neutral)
        var formData = new FormData();
        formData.append("image", b64toBlob(imageSrc));
        formData.append("requests", shots.length);
        setPercentage((shots.length * 16) + 4);
        // let reading = {
        //     pitchDegree: angle.pitchDegree, rollDegree: angle.rollDegree, yawDegree: angle.yawDegree,
        //     imageDistance: `${box.width},${box.height}`,
        //     emotionNeutral: neutral,
        //     demoToken: demoToken
        // }

        dispatch(uploadToBfm(formData, demoDetialByToken));


        if (shots && shots.length === 6) {

            let stream = webcamRef.current.video.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
            webcamRef.current.video.srcObject = null;
            toggle();
            setShowCamera(false)
            dispatch(setCompleted(true));
            return;
        } else {
            toggle();
            setTimeout(() => {
                capture1();
            }, 2000);
        }
    };


    // const manualCapture = async (angle, box, neutral) => {
    //     // const imageSrc = webcamRef.current.getScreenshot();
    //     // dispatch(setCaptureStatus("capture"));
    //     sessionStorage.setItem("captureType", "capture")

    //     setError("Hold Your Pose")
    //     // shots.push({ image: b64toBlob(imageSrc) });
    //     // console.log(angle, 'imageAngleimageAngle', neutral)
    //     // var formData = new FormData();
    //     // formData.append("image", b64toBlob(imageSrc));
    //     // formData.append("requests", shots.length);
    //     // setPercentage((shots.length * 16) + 4);


    //     // let reading = {
    //     //     pitchDegree: 0, rollDegree: 0, yawDegree: 0,
    //     //     imageDistance: `${0}x${0}`,
    //     //     emotionNeutral: 0,
    //     //     demoToken: demoToken
    //     // }
    //     // dispatch(uploadToBfm(formData, demoDetialByToken, reading));
    //     // // dispatch(setImageReading(reading))
    //     // if (shots && shots.length === 6) {
    //     //     let stream = webcamRef.current.video.srcObject;
    //     //     const tracks = stream.getTracks();
    //     //     tracks.forEach(track => track.stop());
    //     //     webcamRef.current.video.srcObject = null;
    //     //     toggle();
    //     //     console.log(webcamRef.current.video, 'webcamRef.current.video')
    //     //     setShowCamera(false)
    //     //     dispatch(setCompleted(true));
    //     //     return;
    //     // } else {
    //     //     toggle();
    //     //     setTimeout(() => {
    //     //         manualCapture();
    //     //     }, 2000);
    //     // }
    // };

    const tryAgain = (e) => {
        dispatch(setCompleted(false));
        dispatch(cameraSelfieError(undefined))
        shots = [];
        setPercentage(null);
        setError("")
        // sessionStorage.setItem("captureType", "")
        // runFacemesh()
        setShowCamera(true)

    };




    //  Load posenet
    // const runFacemesh = async () => {
    //     // OLD MODEL
    //     // const net = await facemesh.load({
    //     //   inputResolution: { width: 640, height: 480 },
    //     //   scale: 0.8,
    //     // });
    //     // NEW MODEL
    //     const model = facemesh.SupportedModels.MediaPipeFaceMesh;
    //     // const net = await facemesh.load(facemesh.SupportedModels.MediaPipeFaceMesh);

    //     const detectorConfig = {
    //         runtime: 'mediapipe', // or 'tfjs'
    //         solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh',
    //     }
    //     const detector = await facemesh.createDetector(model, detectorConfig);
    //     startVideo();

    //     setInterval((e) => {

    //         detect(detector);
    //     }, 3000);
    // };

    // const startVideo = () => {
    //     setStop(false);
    //     navigator.mediaDevices
    //         .getUserMedia({ video: { width: 300 } })
    //         .then(stream => {
    //             let video = webcamRef.current.video;
    //             video.srcObject = stream;
    //             video.play();
    //         })
    //         .catch(err => {
    //             console.error("error:", err);
    //         });
    // }

    // const detect = async (net, captureStatus2) => {
    //     if (
    //         typeof webcamRef.current !== "undefined" &&
    //         webcamRef.current !== null &&
    //         webcamRef.current.video.readyState === 4
    //     ) {
    //         // Get Video Properties
    //         const video = webcamRef.current.video;
    //         const videoWidth = webcamRef.current.video.videoWidth;
    //         const videoHeight = webcamRef.current.video.videoHeight;

    //         // Set video width
    //         webcamRef.current.video.width = videoWidth;
    //         webcamRef.current.video.height = videoHeight;

    //         // Set canvas width
    //         canvasRef.current.width = videoWidth;
    //         canvasRef.current.height = videoHeight;

    //         // Make Detections
    //         // OLD MODEL
    //         //       const face = await net.estimateFaces(video);
    //         // NEW MODEL
    //         let detections = [];
    //         if (canvasRef && canvasRef.current) {
    //             canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(webcamRef.current.video);
    //             const displaySize = {
    //                 width: videoWidth,
    //                 height: videoHeight
    //             }

    //             faceapi.matchDimensions(canvasRef.current, displaySize);

    //             detections = await faceapi.detectAllFaces(webcamRef.current.video,
    //                 new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();
    //             let resizedDetections = faceapi.resizeResults(detections, displaySize);
    //             setdetectionsValue(detections);

    //         }

    //         const face = await net.estimateFaces(video);
    //         let captureType = sessionStorage.getItem("captureType")
    //         console.log(face, 'isCaptureisCapture', detections)
    //         console.log(captureType, 'captureType')
    //         if (captureType !== "capture") {
    //             if (face && face.length > 0 && detections && detections.length > 0) {
    //                 // console.log(face[0].keypoints);
    //                 if (shots.length === 0) {
    //                     // dispatch(setCaptureStatus("auto"))
    //                     sessionStorage.setItem("captureType", "auto")
    //                     getErrorMessage(face, detections[0])
    //                 } else {
    //                     // dispatch(setCaptureStatus("auto"))
    //                     sessionStorage.setItem("captureType", "auto")
    //                     calculateFaceAngle(face, detections[0], true)
    //                 }

    //             } else {
    //                 // console.log("llllllllll", face)
    //                 if (shots.length === 0)
    //                     setError("No faces detected")
    //             }
    //         } else {
    //             if (captureType === "capture") {
    //                 if (face && face.length > 0 && detections && detections.length > 0) {
    //                     // console.log(face[0].keypoints);
    //                     calculateFaceAngle(face, detections[0], true)

    //                 }
    //             }
    //         }

    //         // Get canvas context
    //         // const ctx = canvasRef.current.getContext("2d");
    //         // requestAnimationFrame(() => { drawMesh(face, ctx) });
    //     }
    // };

    // const calculateFaceAngle = (face, detection, isCapture) => {
    //     console.log(face, 'kkkkkkkkkkkkkkkkkk')
    //     let mesh = face[0].keypoints
    //     if (!mesh) return { rollDegree: 0, yawDegree: 0, pitchDegree: 0 };
    //     const radians = (a1, a2, b1, b2) => Math.atan2(b2 - a2, b1 - a1);
    //     const angle = {
    //         // roll is face lean left/right
    //         // looking at x,y of outside corners of leftEye and rightEye
    //         roll: radians(mesh[33].x, mesh[33].y, mesh[263].x, mesh[263].y),
    //         rollDegree: calculateDegree(radians(mesh[33].x, mesh[33].y, mesh[263].x, mesh[263].y)),
    //         // yaw is face turn left/right
    //         // looking at x,z of outside corners of leftEye and rightEye
    //         yaw: radians(mesh[33].x, mesh[33].z, mesh[263].x, mesh[263].z),
    //         yawDegree: calculateDegree(radians(mesh[33].x, mesh[33].z, mesh[263].x, mesh[263].z)),
    //         // pitch is face move up/down
    //         // looking at y,z of top and bottom points of the face
    //         pitch: radians(mesh[10].y, mesh[10].z, mesh[152].y, mesh[152].z),
    //         pitchDegree: calculateDegree(radians(mesh[10].y, mesh[10].z, mesh[152].y, mesh[152].z)),
    //     };

    //     setImageAngle(angle)
    //     if (isCapture) {
    //         toggle();
    //         capture1(angle, face[0].box, detection.expressions.neutral);
    //     }

    //     // console.log(angle, 'angleangleangle')
    //     return angle;
    // }



    // const calculateDegree = (radian) => {
    //     return radian * 180 / 3.142
    // }
    // React.useEffect(() => {
    //     const loadModels = async () => {
    //         const MODEL_URL = process.env.PUBLIC_URL + '/models';

    //         Promise.all([
    //             faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
    //             faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
    //             faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
    //             faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
    //         ]).then();
    //     }
    //     loadModels();
    // }, []);


    // useEffect(() => {
    //     runFacemesh()
    // }, []);

    useEffect(() => {
        // sessionStorage.setItem("captureType", "")
    }, [])




    // const getErrorMessage = (face, mesh) => {

    //     const angle = calculateFaceAngle(face, false);
    //     // let landmarks = null;
    //     // const leftEye = null;
    //     // const rightEye = null;
    //     // let mesh = null;
    //     // if (detectionsValue && detectionsValue.length > 0) {
    //     //     mesh = detectionsValue[0];
    //     //     landmarks = detectionsValue[0].landmarks;
    //     //     leftEye = detectionsValue[0].getLeftEye()
    //     //     rightEye = detectionsValue[0].getRightEye()
    //     // }

    //     let landmarks = mesh.landmarks;
    //     const leftEye = landmarks.getLeftEye()
    //     const rightEye = landmarks.getRightEye()

    //     // console.log(leftEye, 'pupilLeftpupilLeft', rightEye)
    //     // console.log(landmarks._imgDims, 'landmarks._imgDims')

    //     let message = "";
    //     // console.log(calculateDegree(angle.yaw), calculateDegree(angle.pitch), calculateDegree(angle.roll))


    //     // Pitch = -0.01 to -0.179
    //     // Roll -0.328 to 0.22
    //     // yaw= -0.087 to -0.04

    //     // console.log(angle, 'angle -yaw - roll - pitch')



    //     if (angle.yawDegree >= 180) {
    //         message = "Kindly Face the Camera";
    //         setError(message)
    //         return;
    //     }
    //     if (angle.yawDegree <= -180) {
    //         message = "Kindly Face the Camera";
    //         setError(message)
    //         return;
    //     }

    //     if (angle.pitchDegree >= 180) {
    //         message = "Kindly Keep your Head Upright";
    //         setError(message)
    //         return;
    //     }
    //     if (angle.pitchDegree <= -180) {
    //         message = "Kindly Keep your Head Upright";
    //         setError(message)
    //         return;
    //     }

    //     if (angle.rollDegree >= 180) {
    //         message = "Your Face is too Elevated";
    //         setError(message)
    //         return;
    //     }
    //     if (angle.rollDegree <= -180) {
    //         message = "Your Face is too Elevated";
    //         setError(message)
    //         return;
    //     }


    //     if (face[0].box.width > 100) {
    //         message = "You are too close to the Camera";
    //         setError(message)
    //         return;

    //     }
    //     if (face[0].box.width < 80) {
    //         message = "You are too far away from the Camera";
    //         setError(message)
    //         return;

    //     }

    //     if (!leftEye || !rightEye) {
    //         message = "Your Eyes are Obscured";
    //         setError(message)
    //         return;
    //     }
    //     if (mesh.expressions.neutral < 0.90) {
    //         message = "Kindly retain a Neutral Expression";
    //         setError(message)
    //         return;
    //     }
    //     console.log(face[0].box.width, 'facefaceface', face[0].box.height)

    //     setError("Hold Your Pose")
    //     if (!message) {
    //         toggle();
    //         capture1(angle, face[0].box, mesh.expressions.neutral);
    //     }
    // }


    return (
        <>
            <div className="logo-bottom-demonstration">
                <img src={resellerAccountData?.headerLogoFile_PathName} alt={resellerAccountData?.tradingName} />
                <h2 style={{ color: resellerAccountData?.hexColor }}>{resellerAccountData?.tradingName}</h2>
            </div>
            <div className="container-fluid mx-auto sign-onbrdg mt-1 pt-5 demonstration-main">

                <div className="demonstration-heading">
                    <h2>Demonstrator </h2>
                </div>
                {!isCompleted && (
                    <>
                        <div className="d-flex justify-content-center mb-3 bread-crumbs">
                            <ul className="list-unstyled d-flex">
                                <li className="first-c">Snapshot  -</li>
                                <li className={step === 1 ? "active" : "d-active"} onClick={() => setStep(1)}>Step 1 <i className="fa fa-angle-right" ></i> </li>
                                <li className={step === 2 ? "active" : "d-active"} >Step 2</li>
                            </ul>
                        </div>
                        <div className="photo-div mb-5">
                            <div className="col-lg-9 col-md-12 m-auto   col-sm-12 create-campaign-main">
                                <div className="row">
                                    <div className="col-lg-3 col-md-12 col-sm-12 create-campaign-left border-top pt-4">
                                        <h5>Taking your photo</h5>
                                        <p>Please authorize any requested permissions in your browser to allow us to use your webcam</p>
                                        <div className="p-3 pink-box2 mt-3">
                                            <p> We are using TR Recruiter to create an accurate psychometric/Soft Skills profile.</p>
                                            <p> We will take a short series of rapid fire photos using the camera on your device to create this profile. </p>
                                            <p> Once your profile has been created your image will be immediately deleted from our files and records.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-9 col-md-12 col-sm-12 create-campaign-right photo-box">
                                        <div className="create-rigth-back pb-4">
                                            <div className="row py-3">
                                                <div className="col-md-5 text-center justify-content-center">
                                                    <div className="rounded-div1">
                                                        {/* {showCamera &&
                                                            <>
                                                                {detectionsValue && detectionsValue.length > 0 && detectionsValue[0].expressions.neutral > 0.90 ?
                                                                    <img src={greenframe} className="frame-img2 img-fluid" alt="" /> :
                                                                    <img src={redframe} className="frame-img2 img-fluid" alt="" />
                                                                }
                                                            </>
                                                        } */}
                                                        {showCamera &&
                                                            <>
                                                            <img src={frame} className="frame-img2 img-fluid" alt="" />
                                                                {/* {error &&
                                                                    error.toLowerCase().replace(/ /g, '') === "holdyourpose" ?
                                                                    <img src={greenframe} className="frame-img2 img-fluid" alt="" /> :
                                                                    <img src={redframe} className="frame-img2 img-fluid" alt="" />} */}
                                                            </>}
                                                            
                                                        {!showCamera && <img src={frame} className="frame-img2 img-fluid" alt="" />}
                                                        {!showCamera && (<img src={avatar} className="avatarImg1 img-fluid" />)}
                                                        {showCamera && (
                                                            <div className="camera-set2">
                                                                <Webcam
                                                                    ref={webcamRef}
                                                                    mirrored
                                                                    id="webcam"
                                                                    audio={false}
                                                                    screenshotFormat="image/jpeg"
                                                                    screenshotQuality={0.8}
                                                                    style={{
                                                                        position: "absolute",
                                                                        marginLeft: "auto",
                                                                        marginRight: "auto",
                                                                        left: 0,
                                                                        right: 0,
                                                                        textAlign: "center",
                                                                        zindex: 9,
                                                                        width: 280,
                                                                        height: 206,
                                                                        objectFit: 'fill',
                                                                    }}
                                                                />

                                                                {/* <canvas
                                                                    ref={canvasRef}
                                                                    style={{
                                                                        position: "absolute",
                                                                        marginLeft: "auto",
                                                                        marginRight: "auto",
                                                                        left: 0,
                                                                        right: 0,
                                                                        textAlign: "center",
                                                                        zindex: 9,
                                                                        width: 280,
                                                                        height: 206,
                                                                        objectFit: 'fill',
                                                                    }}
                                                                /> */}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {!capture ? (
                                                        <button
                                                            className="px-3 ml-0 mr-2 btn campaign-btn-new mt-4 px-5"
                                                            onClick={cameraReady}> Ready
                                                        </button>
                                                    ) : (
                                                        <>

                                                            {/* {error && <span className={`${error.toLowerCase().replace(/ /g, '') === "holdyourpose" ? "greensignal" : "redsignal"}`}>{error}</span>} */}

                                                            {/* {detectionsValue && detectionsValue.length > 0 && detectionsValue[0].expressions.neutral > 0.90 ?
                                                                <span className="greensignal">Hold Your Pose</span> : <span className="redsignal">Come closer to camera</span>
                                                            } */}

                                                            <br />
                                                            {/* <button
                                                                className="px-3 ml-0 mr-2 btn campaign-btn-new mt-4 px-5"
                                                                onClick={(e) => startVideo(e)} > Play
                                                            </button> */}
                                                            <button
                                                                className="px-3 ml-0 mr-2 btn campaign-btn-new mt-4 px-5"
                                                                onClick={(e) => capture1(e)}
                                                                // disabled={!detectionsValue || detectionsValue.length == 0}
                                                            > Capture

                                                            </button>
                                                        </>


                                                    )}
                                                </div>
                                                <div className="col-md-7">
                                                    <ul className="avatar-ul">
                                                        <li><i className="mr-3 fa fa-check" aria-hidden="true"></i><span>Please adopt a passport pose</span></li>
                                                        <li><i className="mr-3 fa fa-check" aria-hidden="true"></i><span>Ensure your head and shoulders fills the lens</span></li>
                                                        <li><i className="mr-3 fa fa-check" aria-hidden="true"></i><span>Ensure both ears are showing or both edges of your face</span></li>
                                                        <li><i className="mr-3 fa fa-check" aria-hidden="true"></i><span>Avoid smiling or having your mouth open</span></li>
                                                        <li><i className="mr-3 fa fa-check" aria-hidden="true"></i><span>Remove any glasses</span></li>
                                                        <li><i className="mr-3 fa fa-check" aria-hidden="true"></i><span>Do not rotate or tilt your head</span></li>
                                                        <li><i className="mr-3 fa fa-check" aria-hidden="true"></i><span>When ready please click the button</span></li>
                                                        {/* <li><i className="mr-3 fa fa-check" aria-hidden="true"></i><span>When the indicators turn green, you will hear the sound of camera shutter clicks, hold your pose until these end.</span></li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="create-devider"></div>
                                            {errorUploading && errorUploading === 400 ? <p>Face not found. Please Try again</p> : ""}
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: percentage === null ? "0%" : `${percentage}%` }}
                                                >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {/* <div id="screenshot"></div> */}


                {isCompleted && (
                    <div className="container-fluid ">
                        <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-12 left-img ">
                            </div>

                            <div className="col-lg-8 col-md-8 col-sm-12 right-content pb-3 pt-3">
                                {errorUploading && errorUploading === 400 || errorUploading === 401 || errorUploading === 403 || errorUploading === 404 ?
                                    <><div className="col-lg-12 col-md-12 col-sm-12 section-form pt-4 pb-5 text-center">
                                        <h3 > We can't take the Photo. Please try again </h3>
                                    </div>
                                        <div style={{ display: 'flex', justifyContent: "center" }}>
                                            < button
                                                className="px-3 ml-0 mr-2 btn campaign-btn-new mt-4 px-5 d-flex justify-content-center"
                                                style={{ display: 'flex', justifyContent: "center" }}
                                                onClick={(e) => tryAgain(e)}
                                            >    Try Again
                                            </button>
                                        </div>
                                    </>
                                    :
                                    errorUploading && errorUploading >= 500 ? <ApplicationProblem />

                                        : <>

                                            <div className="demonstration-allocate-none">
                                                <p>
                                                    Thank you {demoDetialByToken?.contactFname} for taking the Snapshot. <br />

                                                </p>
                                                <p>
                                                    We have sent you an email attached to which is your Snapshot analysis. Please note note that this analysis is private and you
                                                    will be the only recipient. <br />

                                                </p>
                                                <p>
                                                    We ask that you give a copy of the analysis to a close work colleague and ask them to grade its accuracy on a scale of 1 to 5 (5
                                                    being the most accurate). On the email you will see a link which, when clicked, will take you to the screen allowing you to enter
                                                    this grade. <br />

                                                </p>
                                                {/* <p>
                                                    Please note that until you enter the grade, {demoDetialByToken?.demoAcc_Allocator} will NOT be able to issue any more Taste Tests.
                                                </p> */}
                                            </div>

                                        </>}
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 left-img ">
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </>
    )
}
export default ProfileCapture;