export const GET_RUN_CARD = "GET_RUN_CARD";
export const SET_LOADER_ADMIN_DAHBOARD = "SET_LOADER_ADMIN_DAHBOARD";
export const SET_LOADER_ADMIN_USERS = "SET_LOADER_ADMIN_USERS";
export const SET_ADMIN_USERS = "SET_ADMIN_USERS";
export const SET_LOADER_CREATE_ADMIN_USER = "SET_LOADER_CREATE_ADMIN_USER";
export const SET_LOADER_FETCH_USERS = "SET_LOADER_FETCH_USERS";
export const SET_USERS = "SET_USERS";
export const SET_LOADER_USERS = "SET_LOADER_USERS";
export const SET_SECTOR_TYPES = "SET_SECTOR_TYPES";
export const SET_SALES_AGENTS = "SET_SALES_AGENTS";
export const SET_CONTRACT_DURATION = "SET_CONTRACT_DURATION";
export const SET_SELECTED_USER_DETAILS = "SET_SELECTED_USER_DETAILS";
export const SET_SELECTED_USER = "SET_SELECTED_USER"
