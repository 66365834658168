import React from "react";
import { AllocateNone } from "../../store/models/reseller.interface";

const DemonstratorAllocateNone = (props: AllocateNone) => {
    const { demoDetails, resellerAccountData } = props
    return (
        <>
            <div className="container">
                <div className="logo-bottom-demonstration">
                    <img src={resellerAccountData?.headerLogoFile_PathName} alt={resellerAccountData?.tradingName} />
                    <h2 style={{color:resellerAccountData?.hexColor}}>{resellerAccountData?.tradingName}</h2>
                </div>
                <div className="mx-auto sign-onbrdg mt-1 pt-5 demonstration-main">
                    <div className="demonstration-heading">
                        <h2>Demonstrator </h2>
                    </div>
                    <div className="demonstration-allocate-none">
                        <p>Hello {demoDetails?.demoScr?.contactFname}</p>
                        <p>
                            You have used your allocation of {demoDetails?.attemptsCompleted + demoDetails?.noOfAttemptsAllowed} Snapshot.
                        </p>
                        <p>
                            If you would like some more Snapshot, please use this <a href={`${demoDetails?.tasteTestMore?.emO_Link}`}>Link</a>
                        </p>
                    </div>

                </div>
            </div>
        </>
    );
};

export default DemonstratorAllocateNone;
