import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { confirmSignup, setSignupConfirmation } from '../../../store/actions/onboardingWizard.action'
import { IStateType } from '../../../store/models/root.interface';
import logo from '../../../assets/logo.png';

const ConfirmSignup: React.FC = () => {
    interface ParamTypes {
        token: string;
    }
    const dispatch = useDispatch();
    const token = useParams<ParamTypes>();
    const confirmSignedUp = useSelector((state: IStateType) => state.onboardingWizard.confirmSignedUp)

    useEffect(() => {
        dispatch(confirmSignup(token.token))
    }, [token.token])
    const resellerAccData = localStorage.getItem('reseller_account') ?
        JSON.parse(localStorage.getItem('reseller_account') || "") : null;

    let logoDetail = null;
    if (resellerAccData && resellerAccData.reseller_AccountLogo) {
        logoDetail = resellerAccData.reseller_AccountLogo.find((item: { key: string; }) => item.key.toLowerCase().replace(/ /g, "") === 'signup')
    }
    return (
        <>
            <div className="bg-login-image-main">
                <div className="confirm-signup-card-center">
                    <div className="confirm-signup-card">
                        <div className="logo-image" style={{ background: logoDetail && logoDetail.headerLogoPathname ? `url(${logoDetail.headerLogoPathname})` : `url(${logo})` }}></div>
                        <div className="login-banner-new">
                            <h1 className="confirmsignup-heading" style={{ color: logoDetail && logoDetail.hex && `${logoDetail.hex}` }}>TR Recruiter</h1>
                        </div>

                        <div className="container px-0 p-160-cont ">
                            {confirmSignedUp !== 0 && confirmSignedUp !== 200 &&
                                <div className="content error-confirm login-main" style={{ marginTop: "50px" }}>
                                    Oops! Looks like confirmation token has been expired.
                                </div>
                            }
                            {confirmSignedUp === 200 &&
                                <div className="content success login-main text-center thnx-txt" style={{ marginTop: "50px" }}>
                                    Your email has been confirmed, please click below to login.
                                </div>
                            }
                            {
                                confirmSignedUp === 200 &&
                                <div className="login-next mt-5" style={{ display: "flex", justifyContent: " center" }}>

                                    <a className="btn btn-next login-thnx" href="/login">Login</a>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </ >
    )
}
export default ConfirmSignup;