import React, { Dispatch, useEffect, useState } from "react";
import { Route } from 'react-router'
import "./App.css";
import "./styles/sb-admin-2.min.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Login from "./components/Account/Login";
import Admin from "./components/Admin/Admin";
import { PrivateRoute } from "./common/components/PrivateRoute";
import { AccountRoute } from "./common/components/AccountRoute";
import { appLoad } from "./store/actions/account.actions";
import { useDispatch, useSelector } from "react-redux";
import { GetResellerAccountSetUpDetail } from "./store/actions/reseller.action";

const App: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  // const [resellerAccountData, setResellerAccountData] = useState<any>();

  useEffect(() => {
    dispatch(GetResellerAccountSetUpDetail())
  }, [dispatch])



  // useEffect(() => {
  //   const resellerAccData = JSON.parse(localStorage.getItem('reseller_account') || '{}');
  //   setResellerAccountData(resellerAccData);
  // }, [])

  // console.log(resellerAccountData?.reseller_AccountLogo, "resellerAccountData>>>>>>>>>")

  useEffect(() => {
    dispatch(appLoad());
  });

  return (
    <div className="App" id="wrapper">
      <Router>
        <Switch>
          <PrivateRoute path="/">
            <Admin />
          </PrivateRoute>
          <AccountRoute path="/login">
            <Login />
          </AccountRoute>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
