import React, { Dispatch, useEffect, useState } from "react";
import TextInput from "../../common/components/TextInput";
import { OnChangeModel } from "../../common/types/Form.types";
import { useDispatch, useSelector } from "react-redux";
import { addAllocateData, getdemoAccountDetails, getResellerAccountSetUpDetail } from "../../store/actions/reseller.action";
import { Button } from "react-bootstrap";
import Loader from "../../common/components/loader";
import { useHistory, useParams } from "react-router-dom";
import { IResellerState } from "../../store/models/reseller.interface";
import { ThunkDispatch } from "redux-thunk"

import { AnyAction } from "redux";
type State = { a: string };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;
const DemoDirect: React.FC = () => {
    const demoDetails = useSelector((state: any) => state?.reseller?.demoAccDetail);
    const loader: any = useSelector((state: IResellerState) => state.reseller.isLoader);
    const history = useHistory()
    let params: any = useParams();
    if (!params && !params.linkUrl) {
        history.push('allocateError')
    }
    const resellerAccountData = useSelector((state: IResellerState) => state?.reseller?.resellerAccountData);
    const [isSuccess, setSuccess] = useState(false);
    const dispatch: AppDispatch = useDispatch();
    const [formState, setFormState] = useState({
        demo_Ind: { error: "", value: 0 },
        firstName: { error: "", value: "" },
        surname: { error: "", value: "" },
        email: { error: "", value: "" },
        phone: { error: "", value: "" },
    });

    useEffect(() => {
        dispatch(getResellerAccountSetUpDetail());
        dispatch(getdemoAccountDetails(params?.linkUrl))
    }, [dispatch]);

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({
            ...formState,
            [model.field]: { error: model.error, value: model.value },
        });
        // dispatch(setUserData({ ...wizardUserData, [model.field]: model.value }))
    }
    const allocateData = {
        contactfname: formState.firstName.value,
        contactsname: formState.surname.value,
        contactemail: formState.email.value,
        TelNo: formState.phone.value,
        Acc_Token: params?.linkUrl,
        isDemoDirect: true
    }
    const createAllocate = () => {
        dispatch(addAllocateData(allocateData)).then(response => {
            if (response && response.data) {
                dispatch(getdemoAccountDetails(params?.linkUrl));
                setSuccess(true)
            }
        })
    }
    // if (loader) {
    //     return (
    //         <Loader children={''} isLoading={loader} type={"circle"} />
    //     );
    // }

    const isDisable = (!formState.firstName.value ||
        !formState.surname.value ||
        !formState.email.value ||
        formState.email.error) ? true : false;

    return (
        <>
          
                <div className="container">
                    <div className="logo-bottom-demonstration">
                        <img src={resellerAccountData?.headerLogoFile_PathName} alt={resellerAccountData?.tradingName} />
                        <h2 style={{color:resellerAccountData?.hexColor}}>{resellerAccountData?.tradingName}</h2>
                    </div>
                    {!isSuccess ?
                        <div className="mx-auto sign-onbrdg mt-1 pt-5 demonstration-main">
                            <div className="demonstration-allocate-content pb-3">
                                <div className="demonstration-heading">
                                    <h2>Demo Direct</h2>
                                </div>
                                <p>Hello {demoDetails?.demoScr?.contactFname}</p>
                                <p>
                                   Completing this form will result in an email to the subject (below)
                                   on which there will be a link allowing them to take the Snapshot.
                                </p>
                            </div>
                            <div className="demonstration-divider"></div>
                            <form
                                name="demonstrationSetUp"
                                className="demonstrationSetUp-form pt-4"
                            >
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput
                                                id="firstName"
                                                type="text"
                                                field="firstName"
                                                value={formState.firstName.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="First Name*"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput
                                                id="surname"
                                                type="text"
                                                field="surname"
                                                value={formState.surname.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Surname*"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput
                                                id="email"
                                                type="text"
                                                field="email"
                                                value={formState.email.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Email*"
                                                placeholder=""
                                                isEmail={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput
                                                id="phone"
                                                type="text"
                                                field="phone"
                                                value={formState.phone.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Phone"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center mt-5">
                                    <Button variant="primary" className="crate-btn-modal"
                                        disabled={isDisable}
                                        onClick={createAllocate}>
                                        SUBMIT
                                    </Button>
                                </div>
                            </form>
                        </div>
                        :
                        <>
                         <div className="mx-auto sign-onbrdg mt-1 pt-5 demonstration-main">
                            <div className="demonstration-heading">
                                <h2>Demo Direct </h2>
                            </div>
                            <div className="demonstration-allocate-none">
                                <p>  Hello {demoDetails?.demoSurveyLast?.contactFname},  </p>
                                <p>
                                You have sent a Snapshot to {demoDetails?.demoSurveyLast?.contactEmail}.
                                </p>
                            </div>
                            </div>
                        </>
                    }
                </div>
            
             
        </>
    );
};

export default DemoDirect;
