import React, { Dispatch, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import TextInput from "../../common/components/TextInput";
import Loader from "../../common/components/loader";
import { useDispatch, useSelector } from "react-redux";
import { IResellerState } from "../../store/models/reseller.interface";
import Form from 'react-bootstrap/Form';
import { getDemoUseDetailsByToken, saveDemoUse, getdemoAccountDetails, sendGdprRefAccesptEmail, sendGdprReffuseEmail, getResellerAccountSetUpDetail } from "../../store/actions/reseller.action";
import { useParams, useHistory } from "react-router-dom";
import ProfileCapture from "./profileCapture";
import DemoRedirect from "./demoRedirect";
import AllocateError from "./allocateError";
import DemoUseRefused from "./demoUseRefused";
import { getIp, saveCampaignForDemoUse } from '../../store/actions/campaign.actions';

import { ThunkDispatch } from "redux-thunk"

import { AnyAction } from "redux";
type State = { a: string };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const DemoUse = () => {
    const { demoToken }: any = useParams();
    const history = useHistory();
    const loader: any = useSelector((state: IResellerState) => state.reseller.isLoader);
    const demoDetails = useSelector((state: any) => state?.reseller?.demoAccDetail);
    const dispatch: AppDispatch = useDispatch()
    const [step, setStep] = useState(1);
    const [section, setSection] = useState(-1);
    const resellerAccountData = useSelector((state: IResellerState) => state?.reseller?.resellerAccountData);
    const [formState, setFormState] = useState({
        firstName: { error: "", value: "" },
        email: { error: "", value: "" },
        phone: { error: "", value: "" },
        authorise: { value: false },
        termsAndConditions: { value: false },
        notProceed: { value: false }
    });

    useEffect(() => {
        dispatch(getIp());
        dispatch(getResellerAccountSetUpDetail());
        dispatch(getDemoUseDetailsByToken(demoToken)).then((res): any => {
            if (res) {
                const { fullName = "", contactEmail = null, telNo = null, psyFile = null, acc_Token = null, applicant_PAYG_Ind = 0, demoToken = null, gdpR_Decline = 0 } = res
                dispatch(getdemoAccountDetails(acc_Token));
                if (psyFile) {
                    history.push(`/demoUse5/${demoToken}`);
                }

                if (gdpR_Decline == 1) {
                    setSection(2)
                    dispatch(sendGdprRefAccesptEmail(demoToken));
                } else {
                    setSection(1)
                }
                if (applicant_PAYG_Ind && applicant_PAYG_Ind > 0) {
                    setStep(2)
                }
                setFormState({
                    firstName: { error: "", value: fullName },
                    email: { error: "", value: contactEmail },
                    phone: { error: "", value: telNo },
                    authorise: { value: false },
                    termsAndConditions: { value: false },
                    notProceed: { value: false }
                })
            } else {
                setSection(-11)
            }
        });
    }, [dispatch, demoToken]);

    function hasFormValueChanged(model: any): void {

        setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } })
        // dispatch(setUserData({ ...wizardUserData, [model.field]: model.value }))
    }

    const handleSubmitNext = (e: any) => {
        if (loader) {
            return (
                <Loader children={''} isLoading={loader} type={"circle"} />
            );
        }
        const data = {
            ...formState,
            FullName: formState.firstName.value,
            contactemail: formState.email.value,
            TelNo: formState.phone.value,
            IsAuthorise: formState.authorise.value,
            Isterms: formState.termsAndConditions.value,
            IsNotProceed: formState.notProceed.value,
            demoToken: demoToken,
            applicant_PAYG_Ind: 0
        }

        const data2 = {
            AccountNo: JSON.stringify(demoDetails.demoAcc_Ind),
            ApplicationID: 0,
            Campaign: JSON.stringify(demoDetails.demoAcc_Ind),
            Link: demoToken,
            email: formState.email.value,
            name: formState.firstName.value,
            organizationID: demoToken
        }
        if (formState.notProceed.value) {
            dispatch(saveDemoUse(data)).then((response: any) => {
                dispatch(getDemoUseDetailsByToken(demoToken))
                if (response.data === "isrefused") {
                    setSection(3)
                    dispatch(sendGdprReffuseEmail(demoToken));
                } else {
                    setStep(2)
                }
            });
        } else {
            dispatch(saveCampaignForDemoUse(data2)).then((res): any => {
                if (res && Number(res) > 0) {
                    data["applicant_PAYG_Ind"] = Number(res);
                    dispatch(saveDemoUse(data)).then((response: any) => {
                        dispatch(getDemoUseDetailsByToken(demoToken))
                        if (response.data === "isrefused") {
                            setSection(3)
                            dispatch(sendGdprReffuseEmail(demoToken));
                        } else {
                            setStep(2)
                        }
                    });
                }


            })
        }



    }

    if (loader) {
        return (
            <Loader children={''} isLoading={loader} type={"circle"} />
        );
    }

    return (
        <>
            {section == 2 &&
                <DemoRedirect />
            }
            {section == -11 &&
                <AllocateError />
            }
            {section == 3 &&
                <DemoUseRefused />
            }
            {section == 1 &&
                <>
                    {step == 1 &&
                        <div className="">
                            <div className="logo-bottom-demonstration">
                                <img src={resellerAccountData?.headerLogoFile_PathName} alt={resellerAccountData?.tradingName} />
                                <h2 style={{ color: resellerAccountData?.hexColor }}>{resellerAccountData?.tradingName}</h2>
                            </div>
                            <div className="container-fluid mx-auto sign-onbrdg mt-1 pt-5 demonstration-main">

                                <div className="col-lg-9 m-auto col-md-12 col-sm-12 create-campaign-main border-bottom">
                                    <div className="demonstration-heading mb-5">
                                        <h2>Demonstrator</h2>
                                    </div>
                                    <div className="row pt-5">
                                        <div className="col-lg-3 col-md-12 col-sm-12 demonstrationSetUp-left">
                                            <hr />
                                            <h5>Personal Details</h5>
                                            <p>please enter your personal details</p>
                                        </div>
                                        <div className="col-lg-9 col-md-12 col-sm-12 demonstrationSetUp-right">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <TextInput
                                                            id="firstName"
                                                            type="text"
                                                            field="firstName"
                                                            value={formState.firstName.value}
                                                            onChange={hasFormValueChanged}
                                                            required={true}
                                                            isFullname={true}
                                                            maxLength={100}
                                                            label="Full Name*"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <TextInput
                                                            id="email"
                                                            type="text"
                                                            field="email"
                                                            value={formState.email.value}
                                                            onChange={hasFormValueChanged}
                                                            required={true}
                                                            maxLength={100}
                                                            label="Email*"
                                                            placeholder=""
                                                            isEmail={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <TextInput
                                                            id="phone"
                                                            type="text"
                                                            field="phone"
                                                            value={formState.phone.value}
                                                            onChange={hasFormValueChanged}
                                                            required={false}
                                                            maxLength={100}
                                                            label="Phone"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="demonstration-divider"></div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-12 col-sm-12 demonstrationSetUp-left">
                                            <hr />
                                            <h5>TR Recruiter Authorization</h5>
                                            <p> You will take a photo on your webcam from
                                                which we extract a psychometric/Soft Skills profile.
                                                <br />
                                            </p>
                                            <p> If you don't have a webcam on your PC please
                                                use your phone!</p>
                                        </div>
                                        <div className="col-lg-9 col-md-12 col-sm-12 demonstrationSetUp-right">
                                            <div className="row ">
                                                <div className="mb-3 demonstrationSetUp-heighlightcolor-demouse">
                                                    <p>
                                                        We are using TR Recruiter to create an
                                                        accurate psychometric/Soft Skills profile.
                                                    </p>
                                                    <p>
                                                        Once authorised we will take a short
                                                        series of rapid fire photos using the
                                                        camera on your device to create this
                                                        profile. Once your profile has been
                                                        created your image will be immediately
                                                        deleted from our files and records.
                                                    </p>
                                                </div>
                                                <div className="demonstrationSetUp-demouse-form">
                                                    <div className="row">
                                                        <div>
                                                            <div className="form-check mt-3 cust-check">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id="authorise"
                                                                    onChange={(e) => setFormState({ ...formState, authorise: { value: e.target.checked } })}
                                                                    checked={formState.authorise.value}
                                                                />
                                                                <label className="form-check-label" >I authorise the use of TR Recruiter*</label>
                                                            </div>
                                                            <div className="form-check mt-3 cust-check">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id="termsAndConditions"
                                                                    onChange={(e) => setFormState({ ...formState, termsAndConditions: { value: e.target.checked } })}
                                                                    checked={formState.termsAndConditions.value}
                                                                />
                                                                <label className="form-check-label" >I agree with the Terms and Conditions and the Privacy Policy*</label>
                                                            </div>
                                                        </div>
                                                        <div className="form-check mt-3 cust-check">
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="notProceed"
                                                                onChange={(e) => setFormState({ ...formState, notProceed: { value: e.target.checked } })}
                                                                checked={formState.notProceed.value}
                                                            />
                                                            <label className="form-check-label" htmlFor="exampleCheck1">I do not wish to proceed</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex mt-3 demonstrationSetUp-demouse-buttons">
                                                    <Button variant="primary" className="crate-btn-modal"
                                                        disabled={
                                                            (!formState.firstName.value ||
                                                                !formState.email.value
                                                            ) ||
                                                            (formState.notProceed.value !== true &&
                                                                (formState.authorise.value !== true ||
                                                                    formState.termsAndConditions.value !== true))
                                                        }
                                                        onClick={handleSubmitNext}>
                                                        Next
                                                    </Button>
                                                    <p>
                                                        Make sure you have a webcam on your
                                                        PC or please use your phone for this Taste Test!
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {step == 2 &&
                        <ProfileCapture setStep={setStep} step={step} />
                    }
                </>
            }
        </>
    );
};

export default DemoUse;
