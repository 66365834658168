import React from "react";
import { useSelector } from "react-redux";
import { AllocateNone } from "../../store/models/reseller.interface";

const ApplicationProblem = ({ formState }: any) => {
    const demoDetails = useSelector((state: any) => state?.reseller?.demoAccDetail);
    let name = "";
    if (formState && window.location.pathname.toLowerCase().includes('candidatewizard')) {
        name = formState.fullName.value
    }
    return (
        <>
            <div className="container">
            <div className="demonstration-allocate-none">
                        <p>Thank you {window.location.pathname.toLowerCase().includes('candidatewizard') ? name : demoDetails?.demoSurveyLast?.lastName} for taking the Snapshot. </p>
                        <p>
                            Unfortunately due  to a system failure we cannot currently upload you imaging.
                            But as soon as we have cleared the problem we will email you with a link which
                            will allow you to complete your application.
                        </p>
                        <p>
                            In the meantime we are sorry for the inconvenience caused.
                        </p>
                    </div>
            </div>
        </>
    );
};

export default ApplicationProblem;
