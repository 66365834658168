import React, { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendAllocateSurveyEmail } from "../../store/actions/reseller.action";
import { AllocateNone } from "../../store/models/reseller.interface";
import { useParams } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk"

import { AnyAction } from "redux";
type State = { a: string };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;

const AllocateSurvey = (props: AllocateNone) => {
    const { demoDetails, resellerAccountData } = props
    const demoAccInd = useSelector((state: any) => state?.reseller?.demoAccDetail?.demoSurveyLast?.demoAccInd)
    const dispatch: AppDispatch = useDispatch()
    let { linkUrl }: any = useParams();
    const [isSuccess, setSuccess] = React.useState(false);
    const sendSurveyEmail = () => {
        dispatch(sendAllocateSurveyEmail(linkUrl)).then(res=>{
            setSuccess(true);
        })
       
    }
    return (
        <>
            <div className="container">
            <div className="logo-bottom-demonstration">
                    <img src={resellerAccountData?.headerLogoFile_PathName} alt={resellerAccountData?.tradingName} />
                    <h2 style={{color:resellerAccountData?.hexColor}}>{resellerAccountData?.tradingName}</h2>
                </div>
                <div className="mx-auto sign-onbrdg mt-1 pt-5 demonstration-main">
                    <div className="demonstration-heading">
                        <h2>Demonstrator </h2>
                    </div>
                    {!isSuccess ?
                        <div className="demonstration-allocate-none">
                            <p>
                                Hello {demoDetails?.demoSurveyLast?.contactFname}  <br />
                                You currently have {demoDetails?.demoSurveyLast?.remAtt} Snapshot remaining and these will expire on {demoDetails?.demoSurveyLast?.dteEx}.<br />
                                However until {demoDetails?.demoSurveyLast?.lastName} has completed their {demoDetails?.demoSurveyLast?.msg}, we cannot
                                release any further Snapshot.<br />
                                To generate an automated email to {demoDetails?.demoSurveyLast?.lastName} click the Next button below. It may also help if you
                                reach out to {demoDetails?.demoSurveyLast?.lastName} yourself and give them a gentle reminder. Once the survey has been
                                completed, to please come back to this page and try again.
                            </p>
                            <div className="d-flex justify-content-center mt-5"><button type="button" className="crate-btn-modal btn btn-primary" onClick={() => sendSurveyEmail()}>
                                <span style={{ padding: "20px" }}>Next</span></button></div>
                        </div>
                        :
                        <div className="demonstration-allocate-none">
                            <p>The reminder email to {demoDetails?.demoSurveyLast?.lastName} has been sent.
                                We recommend that you also reach out with a gentle reminder. </p>
                        </div>
                    }
                </div>

            </div>



        </>
    )
}
export default AllocateSurvey;