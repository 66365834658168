import React from "react";
import { useSelector } from "react-redux";
import { IResellerState } from "../../store/models/reseller.interface";


const AllocateError: React.FC = () => {
    const resellerAccountData = useSelector((state: IResellerState) => state?.reseller?.resellerAccountData);

    return (
        <div className="container">
            <div className="logo-bottom-demonstration">
                <img src={resellerAccountData?.headerLogoFile_PathName} alt={resellerAccountData?.tradingName} />
                <h2 style={{color:resellerAccountData?.hexColor}}>{resellerAccountData?.tradingName}</h2>
            </div>
            <div className="mx-auto sign-onbrdg mt-1 pt-5 demonstration-main">
                <div className="demonstration-heading mb-5">
                    <h2>Demonstrator</h2>
                </div>
                <div className="demonstration-allocate-none text-center">
                    <h1>Ooops!  Demo token not valid...</h1>
                </div>
            </div>
        </div>
    );
};

export default AllocateError;
