import React from "react";
import { useSelector } from "react-redux";

const DemoRedirect = () => {
    const demoDetails = useSelector((state: any) => state?.reseller?.demoAccDetail);
    const resellerAccountData = useSelector((state: any) => state?.reseller?.resellerAccountData);
    return (
        <>
            <div className="container">
                <div className="logo-bottom-demonstration">
                    <img src={resellerAccountData?.headerLogoFile_PathName} alt={resellerAccountData?.tradingName} />
                    <h2 style={{color:resellerAccountData?.hexColor}}>{resellerAccountData?.tradingName}</h2>
                </div>
                <div className="mx-auto sign-onbrdg mt-1 pt-5 demonstration-main">
                    <div className="demonstration-heading">
                        <h2>Demonstrator</h2>
                    </div>
                    <div className="demonstration-allocate-none">
                        <p>Hello {demoDetails?.demoSurveyLast?.contactFname} </p>
                        <p>
                            When {demoDetails?.demoSurveyLast?.demoAcc_Allocator} sent you an invitation to take the TR Recruiter Snapshot, you were not
                            prepared to give us your permission to perform the Test.
                        </p>
                        <p>
                            If you have changed your mind, then you need to contact {demoDetails?.demoSurveyLast?.demoAcc_Allocator} and ask that(s)/he
                            allocates another Test to you.
                        </p>
                    </div>

                </div>
            </div>
        </>
    );
};

export default DemoRedirect;
