import React, { Dispatch, useEffect, useState } from "react";
import TextInput from "../../common/components/TextInput";
import { OnChangeModel } from "../../common/types/Form.types";
import { useDispatch, useSelector } from "react-redux";
import { addAllocateData, getdemoAccountDetails, getResellerAccountSetUpDetail } from "../../store/actions/reseller.action";
import { Button } from "react-bootstrap";
import DemonstratorAllocateNone from "./demonstratorAllocateNone";
import DemonstratorDte from "./demonstratorDte";
import AllocateSurvey from "./allocateSurvey";
import Loader from "../../common/components/loader";
import { useHistory, useParams } from "react-router-dom";
import { IResellerState } from "../../store/models/reseller.interface";
import { ThunkDispatch } from "redux-thunk"

import { AnyAction } from "redux";
type State = { a: string };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;
const DemonstratorAllocate: React.FC = () => {
    const demoDetails = useSelector((state: any) => state?.reseller?.demoAccDetail);
    const loader: any = useSelector((state: IResellerState) => state.reseller.isLoader);
    const history = useHistory()
    let params: any = useParams();
    if (!params && !params.linkUrl) {
        history.push('allocateError')
    }
    const resellerAccountData = useSelector((state: IResellerState) => state?.reseller?.resellerAccountData);
    const [activeSection, setActiveSection] = useState(-1);
    const [isSuccess, setSuccess] = useState(false);
    const dispatch: AppDispatch = useDispatch();
    const [formState, setFormState] = useState({
        demo_Ind: { error: "", value: 0 },
        firstName: { error: "", value: "" },
        surname: { error: "", value: "" },
        email: { error: "", value: "" },
        phone: { error: "", value: "" },
    });

    useEffect(() => {
        dispatch(getResellerAccountSetUpDetail());
        dispatch(getdemoAccountDetails(params?.linkUrl))
            .then((response: any) => {
                if (response && response.data) {
                    if (response && response.data.demoScr) {
                        if ((response.data.noOfAttemptsAllowed) > 0) {
                            var varDate = new Date(response.data.demoScr.dte); //dd-mm-YYYY
                            var today = new Date();
                            if (varDate < today) {
                                setActiveSection(2)
                            } else {
                                if (response.data.demoScr.atCd == response.data.demoScr.syCd) {
                                    setActiveSection(4)
                                } else {
                                    setActiveSection(3)
                                }
                            }
                        } else {
                            setActiveSection(1)
                        }
                    }
                }
            });
    }, [dispatch]);

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({
            ...formState,
            [model.field]: { error: model.error, value: model.value },
        });
        // dispatch(setUserData({ ...wizardUserData, [model.field]: model.value }))
    }
    const allocateData = {
        contactfname: formState.firstName.value,
        contactsname: formState.surname.value,
        contactemail: formState.email.value,
        TelNo: formState.phone.value,
        Acc_Token: params?.linkUrl,
    }
    const createAllocate = () => {
        dispatch(addAllocateData(allocateData)).then(response => {
            if (response && response.data) {
                dispatch(getdemoAccountDetails(params?.linkUrl));
                setSuccess(true)
            }
        })
    }
    if (loader && activeSection !== 3) {
        return (
            <Loader children={''} isLoading={loader} type={"circle"} />
        );
    }

    const isDisable = (!formState.firstName.value ||
        !formState.surname.value ||
        !formState.email.value ||
        formState.email.error) ? true : false;

    return (
        <>
            {activeSection == 4 &&
                <div className="container">
                    <div className="logo-bottom-demonstration">
                        <img src={resellerAccountData?.headerLogoFile_PathName} alt={resellerAccountData?.tradingName} />
                        <h2 style={{color:resellerAccountData?.hexColor}}>{resellerAccountData?.tradingName}</h2>
                    </div>
                    {!isSuccess ?
                        <div className="mx-auto sign-onbrdg mt-1 pt-5 demonstration-main">
                            <div className="demonstration-allocate-content pb-3">
                                <div className="demonstration-heading">
                                    <h2>Demonstrator </h2>
                                </div>
                                <p>Hello {demoDetails?.demoScr?.contactFname}</p>
                                <p>
                                    You have {demoDetails?.demoScr?.attRem} opportunities for
                                    you and your colleagues to 'Snapshot' the TR
                                    Recruiter soft skill analyser, this allocation will
                                    expire on {demoDetails?.demoScr?.dte}.
                                </p>
                                <p>
                                    To ensure that Demonstrator remains within GDPR
                                    guidelines, when you want one of your colleagues to
                                    test, simply enter their details below and press
                                    Submit. The subject will receive an email containing
                                    their own indivdual link and how to use
                                    Demonstrator.
                                </p>                            <p>
                                    We ask that after each Snapshot, the subject
                                    comments on the accuracy, after which you will be
                                    able to allocate a new test.
                                </p>
                            </div>
                            <div className="demonstration-divider"></div>
                            <form
                                name="demonstrationSetUp"
                                className="demonstrationSetUp-form pt-4"
                            >
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput
                                                id="firstName"
                                                type="text"
                                                field="firstName"
                                                value={formState.firstName.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="First Name*"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput
                                                id="surname"
                                                type="text"
                                                field="surname"
                                                value={formState.surname.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Surname*"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput
                                                id="email"
                                                type="text"
                                                field="email"
                                                value={formState.email.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Email*"
                                                placeholder=""
                                                isEmail={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextInput
                                                id="phone"
                                                type="text"
                                                field="phone"
                                                value={formState.phone.value}
                                                onChange={hasFormValueChanged}
                                                required={true}
                                                maxLength={100}
                                                label="Phone"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center mt-5">
                                    <Button variant="primary" className="crate-btn-modal"
                                        disabled={isDisable}
                                        onClick={createAllocate}>
                                        SUBMIT
                                    </Button>
                                </div>
                            </form>
                        </div>
                        :
                        <>
                         <div className="mx-auto sign-onbrdg mt-1 pt-5 demonstration-main">
                            <div className="demonstration-heading">
                                <h2>Demonstrator </h2>
                            </div>
                            <div className="demonstration-allocate-none">
                                <p>  Hello {demoDetails?.demoSurveyLast?.contactFname},  </p>
                                <p>
                                    You have successully allocated one of your Snapshot to {demoDetails?.demoSurveyLast?.lastName},
                                    to whom we have sent an
                                    email containing instructions and a link.
                                    As you will not be able to issue another Test until {demoDetails?.demoSurveyLast?.lastName} has
                                    taken the test and ranked it,
                                    you may find it helpful to let them know.
                                </p>
                                <p>You have {demoDetails?.demoSurveyLast?.remAtt} tests left to allocate until they expire on {demoDetails?.demoSurveyLast?.dteEx}.</p>
                            </div>
                            </div>
                        </>
                    }
                </div>
            }
            {activeSection == 1 &&
                <DemonstratorAllocateNone demoDetails={demoDetails} resellerAccountData={resellerAccountData} />
            }
            {activeSection == 2 &&
                <DemonstratorDte demoDetails={demoDetails} resellerAccountData={resellerAccountData}/>
            }
            {activeSection == 3 &&
                <AllocateSurvey demoDetails={demoDetails} resellerAccountData={resellerAccountData} />
            }
        </>
    );
};

export default DemonstratorAllocate;
