import * as authAction from "./account.actions";
import { get, store } from "../../services/api";

export const GET_DEMONSTRATE_DATA = "GET_DEMONSTRATE_DATA";
export const SET_LOADER = "SET_LOADER";
export const SET_DEMONSTRATE_DATA = "SET_DEMONSTRATE_DATA";
export const ADD_DEMONSTRATE_DATA = "ADD_DEMONSTRATE_DATA";
export const ADD_ALLOCATE_DATA = "ADD_ALLOCATE_DATA";
export const GET_DEMON_DETAIL_DATA = "GET_DEMON_DETAIL_DATA";
export const ADD_ALLOCATE_SURVEY_LAST_IND = "ADD_ALLOCATE_SURVEY_LAST_IND";
export const GET_DEMO_DETAIL_BY_TOKEN = "GET_DEMO_DETAIL_BY_TOKEN";
export const SET_DEMO_USE_DETAILS = "SET_DEMO_USE_DETAILS";
export const SET_DEMO_USE_5_DETAILS = "SET_DEMO_USE_5_DETAILS";
export const GET_DEMO_USE_DETAILS_BY_TOKEN = "GET_DEMO_USE_DETAILS_BY_TOKEN";

export const SET_DEMO_USE_ACCURACY_RESPONSE = "SET_DEMO_USE_ACCURACY_RESPONSE";
export const SET_RESELLER_ACCOUNT_DETAIL = "SET_RESELLER_ACCOUNT_DETAIL";
export const SET_RESELLER_ACCOUNT_INFO = "SET_RESELLER_ACCOUNT_INFO";

export function setLoader(type: any, value: any) {
  return { type: type, value: value };
}

export function setDemostrationData(data: any) {
  return {
    type: SET_DEMONSTRATE_DATA,
    value: data,
  };
}

export function setAddDemonstrateData(data: any) {
  return {
    type: ADD_DEMONSTRATE_DATA,
    value: data,
  };
}

export function setAddAllocateData(data: any) {
  return {
    type: ADD_ALLOCATE_DATA,
    value: data,
  };
}

export function setDemoDetails(data: any) {
  return {
    type: GET_DEMON_DETAIL_DATA,
    value: data,
  };
}

export function setDemoDetailsByDemoToken(data: any) {
  return {
    type: GET_DEMO_DETAIL_BY_TOKEN,
    value: data,
  };
}

export function setDemoUseDetails(data: any) {
  return {
    type: SET_DEMO_USE_DETAILS,
    value: data,
  };
}

export function setDemoUs5eDetails(data: any) {
  return {
    type: SET_DEMO_USE_5_DETAILS,
    value: data,
  };
}

export function setDemoDetailsByToken(data: any) {
  return {
    type: GET_DEMO_USE_DETAILS_BY_TOKEN,
    value: data,
  };
}

export function setDemoUse5Details(data: any) {
  return {
    // type:GET_DEMO_USE5_DETAILS_BY_TOKEN,
    value: data,
  };
}

export function setDemoUseAccuracyResponse(data: any) {
  return {
    type: SET_DEMO_USE_ACCURACY_RESPONSE,
    value: data,
  };
}

export function setResellerAccountSetUpDetail(data: any) {
  return {
    type: SET_RESELLER_ACCOUNT_DETAIL,
    value: data,
  };
}
export function setResellerAccountSetUpInfo(data: any) {
  return {
    type: SET_RESELLER_ACCOUNT_INFO,
    value: data,
  };
}

export function getDemonstrateData() {
  return (dispatch: any) => {
    if (authAction.isTokenExpired()) {
      dispatch(authAction.setExpired(true));
      dispatch(authAction.isUserLoggedIn(false));
      return;
    }
    dispatch(setLoader(SET_LOADER, true));
    get("demonstration/GetDemoSetupSelect")
      .then((response) => {
        dispatch(setDemostrationData(response.data));
        dispatch(setLoader(SET_LOADER, false));
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
        dispatch(setLoader(SET_LOADER, false));
      });
  };
}

export const getDemoUseAccuracy = () => {
  return async (dispatch: any) => {
    dispatch(setLoader(SET_LOADER, true));
    return get("demonstration/GetDemoUseAccuracy")
      .then((response) => {
        console.log(response.data, "response.data");
        dispatch(setDemoUseAccuracyResponse(response.data));
        dispatch(setLoader(SET_LOADER, false));
        return response.data;
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
        dispatch(setLoader(SET_LOADER, false));
      });
  };
};

export const addDemonstrateData = (data: any) => {
  return async (dispatch: any) => {
    if (authAction.isTokenExpired()) {
      await dispatch(authAction.setExpired(true));
      await dispatch(authAction.isUserLoggedIn(false));
      return;
    }
    dispatch(setLoader(SET_LOADER, true));
    return store("demonstration/SetDemoSetup", data)
      .then(async (response) => {
        await dispatch(setAddDemonstrateData(response.data));
        await dispatch(setLoader(SET_LOADER, false));
        return response;
      })
      .catch((error) => {
        console.log(error, "error vvv errorerror");
        dispatch(setLoader(SET_LOADER, false));
      });
  };
};

export const getdemoAccountDetails = (linkUrl: any) => {
  return async (dispatch: any) => {
    dispatch(setLoader(SET_LOADER, true));
    return get(`demonstration/GetDemoAccDetailByLinkUrl/${linkUrl}`)
      .then((response) => {
        dispatch(setDemoDetails(response.data));
        dispatch(setLoader(SET_LOADER, false));
        return response;
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
        dispatch(setLoader(SET_LOADER, false));
      });
  };
};

export function addAllocateData(data: any) {
  return (dispatch: any) => {
    dispatch(setLoader(SET_LOADER, true));
    return store("demonstration/SetDemoAllocateSetup", data)
      .then((response) => {
        dispatch(setAddAllocateData(response.data));
        dispatch(setLoader(SET_LOADER, false));
        return response;
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
        dispatch(setLoader(SET_LOADER, false));
      });
  };
}

export function sendAllocateSurveyEmail(linkUrl: any) {
  return (dispatch: any) => {
    dispatch(setLoader(SET_LOADER, true));
    return get("demonstration/sendSurveyEmail/" + linkUrl)
      .then((response) => {
        dispatch(setLoader(SET_LOADER, false));
        return true;
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
        dispatch(setLoader(SET_LOADER, false));
      });
  };
}

export const getDemoDetailByDemoToken = (linkUrl: any) => {
  return async (dispatch: any) => {
    dispatch(setLoader(SET_LOADER, true));
    return get(`demonstration/GetDemoDetailByDemoToken/${linkUrl}`)
      .then((response) => {
        dispatch(setDemoDetails(response.data));
        dispatch(setLoader(SET_LOADER, false));
        return response;
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
        dispatch(setLoader(SET_LOADER, false));
      });
  };
};

export const saveDemoUse = (data: any) => {
  return async (dispatch: any) => {
    dispatch(setLoader(SET_LOADER, true));
    return store(`demonstration/SetDemoUseSetup`, data)
      .then((response) => {
        dispatch(setDemoUseDetails(response.data));
        dispatch(setLoader(SET_LOADER, false));
        return response;
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
        dispatch(setLoader(SET_LOADER, false));
      });
  };
};

export const getDemoUseDetailsByToken = (demoToken: any) => {
  return async (dispatch: any) => {
    dispatch(setLoader(SET_LOADER, true));
    return get(`demonstration/GetDemoDetailByDemoToken/${demoToken}`)
      .then((response) => {
        dispatch(setDemoDetailsByDemoToken(response.data));
        dispatch(setLoader(SET_LOADER, false));
        return response?.data;
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
        dispatch(setLoader(SET_LOADER, false));
      });
  };
};

export const setDemoUse5 = (data: any) => {
  return async (dispatch: any) => {
    dispatch(setLoader(SET_LOADER, true));
    return store(`demonstration/SetDemoUse5Setup`, data)
      .then((response) => {
        // dispatch(setDemoUse5Details(response.data));
        dispatch(setLoader(SET_LOADER, false));
        return response;
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
        dispatch(setLoader(SET_LOADER, false));
      });
  };
};

export const sendGdprRefAccesptEmail = (demoToken: any) => {
  console.log(demoToken, "token dodmeo ");
  return async (dispatch: any) => {
    dispatch(setLoader(SET_LOADER, true));
    return get(`demonstration/sendGdprRefAccesptEmail/${demoToken}`)
      .then((response) => {
        dispatch(setLoader(SET_LOADER, false));
        return response;
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
        dispatch(setLoader(SET_LOADER, false));
      });
  };
};

export const sendGdprReffuseEmail = (demoToken: any) => {
  console.log(demoToken, "token dodmeo ");
  return async (dispatch: any) => {
    dispatch(setLoader(SET_LOADER, true));
    return get(`demonstration/sendGdprReffuseEmail/${demoToken}`)
      .then((response) => {
        // dispatch(setDemoUse5Details(response.data));
        dispatch(setLoader(SET_LOADER, false));
        return response;
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
        dispatch(setLoader(SET_LOADER, false));
      });
  };
};

export const getResellerAccountSetUpDetail = () => {
  return async (dispatch: any) => {
    dispatch(setLoader(SET_LOADER, true));
    return get(`demonstration/GetResellerAccountSetUpDetail`)
      .then((response) => {
        dispatch(setResellerAccountSetUpDetail(response.data));
        dispatch(setLoader(SET_LOADER, false));
        return response;
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
        dispatch(setLoader(SET_LOADER, false));
      });
  };
};

export const retakeEmail = () => {
  return async (dispatch: any) => {
    return get(`demonstration/RetakeEmail`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
      });
  };
};

export const setImageReading = (data: any) => {
  return async (dispatch: any) => {
    return store(`demonstration/SetImageReading`, data)
      .then((response) => {
        // dispatch(setDemoUse5Details(response.data));

        return response;
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
      });
  };
};
export const GetResellerAccountSetUpDetail = () => {
  return async (dispatch: any) => {
    return get(`demonstration/GetResellerAccountSetUpDetail`)
      .then((response) => {
        // console.log(response, "GetResellerAccountSetUpDetailAction")
        // dispatch(setDemoUse5Details(response.data));
        // const allData=JSON.stringify(response.data)
        if (response) {
          dispatch(setResellerAccountSetUpDetail(response.data));
          localStorage.setItem(
            "reseller_account",
            JSON.stringify(response.data)
          );
          return response;
        }
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
      });
  };
};
export const GetResellerAccountInfo = (id:any) => {
  return async (dispatch: any) => {
    return get(`demonstration/GetResellerAccountSetupInfo/${id}`)
      .then((response) => {
        
        if (response) {
          dispatch(setResellerAccountSetUpInfo(response.data));
           localStorage.setItem(
             "reseller_account_setup_by_id",
             JSON.stringify(response.data)
           );
          return response;
        }
      })
      .catch((error) => {
        console.log(error, "errorerrorerror : GetResellerAccountSetupInfo");
      });
  };
};
