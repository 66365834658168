import React from "react";
import { useSelector } from "react-redux";

const DemoUseRefused = () => {
    const demoDetails = useSelector((state: any) => state?.reseller?.demoAccDetail);
    const resellerAccountData = useSelector((state: any) => state?.reseller?.resellerAccountData);
    return (
        <>
            <div className="container">
                <div className="logo-bottom-demonstration">
                    <img src={resellerAccountData?.headerLogoFile_PathName} alt={resellerAccountData?.tradingName} />
                    <h2 style={{color:resellerAccountData?.hexColor}}>{resellerAccountData?.tradingName}</h2>
                </div>
                <div className="mx-auto sign-onbrdg mt-1 pt-5 demonstration-main">
                    <div className="demonstration-heading">
                        <h2>Demonstrator</h2>
                    </div>
                    <div className="demonstration-allocate-none">
                        <p>Hello {demoDetails?.demoSurveyLast?.contactFname} </p>
                        <p>
                            When {demoDetails?.demoSurveyLast?.demoAcc_Allocator} sent you an invitation to take the TR Recruiter Snapshot, one of his/her
                            limited allocation was used.
                        </p>
                        <p>
                            As you have not given us the authorisation required to take the TR Recruiter Snapshot, this test has been re-assigned back to {demoDetails?.demoSurveyLast?.contactFname}. If you change your mind, please contact {demoDetails?.demoSurveyLast?.contactFname}.
                        </p>
                    </div>

                </div>
            </div>
        </>
    );
};

export default DemoUseRefused;
