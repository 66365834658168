import { IActionBase } from "../models/root.interface";
import { IResellerData } from "../models/reseller.interface";
import {
    ADD_ALLOCATE_SURVEY_LAST_IND, ADD_DEMONSTRATE_DATA, GET_DEMON_DETAIL_DATA, SET_DEMONSTRATE_DATA, SET_LOADER,
    GET_DEMO_DETAIL_BY_TOKEN, SET_DEMO_USE_ACCURACY_RESPONSE, SET_RESELLER_ACCOUNT_DETAIL,
    SET_DEMO_USE_DETAILS,
    SET_RESELLER_ACCOUNT_INFO
} from "../actions/reseller.action";
const initialState: IResellerData = {
    prospectData: { demoCoType: '', demoDays: '', demoQtyTries: '' },
    isLoader: false,
    demonsConfirmId: null,
    demoAccDetail: null,
    demoDetialByToken: null,
    demoUse: null,
    accuracyList: null,
    resellerAccountData: {},
    resellerAccountSetup:{}
};

function resellerReducer(state: IResellerData = initialState, action: IActionBase): IResellerData {
    switch (action.type) {

        case SET_LOADER: {
            return { ...state, isLoader: action.value }
        }

        case SET_DEMONSTRATE_DATA: {
            return {
                ...state,
                prospectData: action.value,
                isLoader: action.value
            }
        }

        case SET_DEMO_USE_ACCURACY_RESPONSE: {
            return {
                ...state,
                accuracyList: action.value
            }
        }

        case ADD_DEMONSTRATE_DATA: {
            return {
                ...state,
                demonsConfirmId: action.value,
                isLoader: action.value
            }
        }
        case GET_DEMON_DETAIL_DATA: {
            return {
                ...state,
                demoAccDetail: action.value,
                isLoader: action.value
            }
        }

        case ADD_ALLOCATE_SURVEY_LAST_IND: {
            return {
                ...state,
                demoAccDetail: action.value,
                isLoader: action.value
            }
        }

        case GET_DEMO_DETAIL_BY_TOKEN: {

            return {
                ...state,
                demoDetialByToken: action.value
            }
        }

        case SET_DEMO_USE_DETAILS: {
            return {
                ...state,
                demoUse: action.value
            }
        }

        case SET_RESELLER_ACCOUNT_DETAIL: {
            return {
                ...state,
                resellerAccountData: action.value
            }
        }
        case SET_RESELLER_ACCOUNT_INFO: {
            return {
                ...state,
                resellerAccountSetup: action.value
            }
        }

        default:
            return state;
    }
}


export default resellerReducer;