import React, { useState, useEffect } from "react";
import TextInput from "../../common/components/TextInput";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { addDemonstrateData, getDemonstrateData, getResellerAccountSetUpDetail } from "../../store/actions/reseller.action";
import { IResellerState } from "../../store/models/reseller.interface";
import { OnChangeModel } from "../../common/types/Form.types";
import { Button } from "react-bootstrap";
import Loader from "../../common/components/loader";
import { ThunkDispatch } from "redux-thunk"

import { AnyAction } from "redux";
type State = { a: string };
type AppDispatch = ThunkDispatch<State, any, AnyAction>;
const DemonstrationSetUp: React.FC = () => {

    const dispatch: AppDispatch = useDispatch()
    const [selectedProspect, setselectedProspect] = useState<{ value: number, label: string }>()
    const [selectedProspect2, setselectedProspect2] = useState<{ value: number, label: string }>()
    const [selectedProspect3, setselectedProspect3] = useState<{ value: number, label: string }>()


    const demonstrationSelectData = useSelector((state: IResellerState) => state?.reseller?.prospectData);

    const resellerAccountData = useSelector((state: IResellerState) => state?.reseller?.resellerAccountData);


    const loader: any = useSelector((state: IResellerState) => state.reseller.isLoader);
    const [isSuccess, setSuccess] = useState(false);
    const [formState, setFormState] = useState({
        firstName: { error: "", value: "" },
        lastName: { error: "", value: "" },
        email: { error: "", value: "" },
        company: { error: "", value: "" },
        prospectType: { error: "", value: "" },
        numberAllowed: { error: "", value: "" },
        expiryDays: { error: "", value: "" }
    });

    useEffect(() => {
        dispatch(getDemonstrateData());
        dispatch(getResellerAccountSetUpDetail());
    }, [dispatch]);

    const protoTypeSelect: { value: any; label: any; }[] = [];
    demonstrationSelectData && demonstrationSelectData?.demoCoType?.length > 0 && demonstrationSelectData?.demoCoType.forEach((demoCoType: { type_Ind: any; descr: any; }) => {
        protoTypeSelect.push({ value: demoCoType.type_Ind, label: demoCoType.descr });
    });
    const numberAllowedSelect: { value: any; label: any; }[] = [];
    demonstrationSelectData && demonstrationSelectData?.demoQtyTries?.length > 0 && demonstrationSelectData?.demoQtyTries.forEach((demoQtyTries: { qty: any; descr: any; }) => {
        numberAllowedSelect.push({ value: demoQtyTries.qty, label: demoQtyTries.descr });
    });
    const expiryDaysSelect: { value: any; label: any }[] = [];
    demonstrationSelectData && demonstrationSelectData?.demoDays?.length > 0 && demonstrationSelectData?.demoDays.forEach((demoDays: { days_Ind: any; descr: any; daysQty: any }) => {
        expiryDaysSelect.push({ value: demoDays.daysQty, label: demoDays.descr });
    });

    function hasFormValueChanged(model: OnChangeModel): void {
        setFormState({ ...formState, [model.field]: { error: model.error, value: model.value } })
        // dispatch(setUserData({ ...wizardUserData, [model.field]: model.value }))
    }

    const handleChangeProspect = (e: any) => {
        setselectedProspect({ value: e.value, label: e.label });
        setFormState({ ...formState, prospectType: { error: e.error, value: e.value } })
    };

    const handleChangeProspect2 = (e: any) => {
        setselectedProspect2({ value: e.value, label: e.label })
        setFormState({ ...formState, numberAllowed: { error: e.error, value: e.value } })
    }

    const handleChangeProspect3 = (e: any) => {
        setselectedProspect3({ value: e.value, label: e.label })
        setFormState({ ...formState, expiryDays: { error: e.error, value: e.value } })
    }

    const create = () => {
        if (loader) {
            return (
                <Loader children={''} isLoading={loader} type={"circle"} />
            );
        }
        const demonstrateData = {
            ContactFname: formState.firstName.value,
            ContactSname: formState.lastName.value,
            ContactEmail: formState.email.value,
            CompanyName: formState.company.value,
            CoType: formState.prospectType.value,
            NoOfAttemptsAllowed: formState.numberAllowed.value,
            DateExpires_Days: formState.expiryDays.value,
        }
        dispatch(addDemonstrateData(demonstrateData)).then((response: any) => {
            if (response && response.data) {
                setSuccess(true)
            }
        });
    }
    if (loader) {
        return (
            <Loader children={''} isLoading={loader} type={"circle"} />
        );
    }

    const isDiable = (!formState.firstName.value ||
        !formState.lastName.value ||
        !formState.email.value ||
        !formState.company.value ||
        formState.email.error ||
        !formState.prospectType.value &&
        !formState.numberAllowed.value &&
        !formState.expiryDays.value) ? true : false

    return (
        <>
            <div className="container">
                <div className="logo-bottom-demonstration">
                    <img src={resellerAccountData?.headerLogoFile_PathName} alt={resellerAccountData?.tradingName} />
                    <h2 style={{color:resellerAccountData?.hexColor}}>{resellerAccountData?.tradingName}</h2>
                </div>
                <div className="mx-auto sign-onbrdg mt-1 pt-5 demonstration-main">
                    {!isSuccess ?
                        <>
                            <div className="demonstration-heading">
                                <h2>Demonstration Set Up</h2>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12 demonstrationSetUp-left">
                                    <h5>Company Details</h5>
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-12 demonstrationSetUp-right">
                                    <form name="demonstrationSetUp" className="demonstrationSetUp-form">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextInput
                                                        id="firstName"
                                                        type="text"
                                                        field="firstName"
                                                        value={formState.firstName.value}
                                                        onChange={hasFormValueChanged}
                                                        required={true}
                                                        maxLength={100}
                                                        label="First Name*"
                                                        placeholder=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextInput
                                                        id="lastName"
                                                        type="text"
                                                        field="lastName"
                                                        value={formState.lastName.value}
                                                        onChange={hasFormValueChanged}
                                                        required={true}
                                                        maxLength={100}
                                                        label="Last Name*"
                                                        placeholder=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextInput
                                                        id="email"
                                                        type="text"
                                                        field="email"
                                                        value={formState.email.value}
                                                        onChange={hasFormValueChanged}
                                                        required={true}
                                                        maxLength={100}
                                                        label="Email*"
                                                        placeholder=""
                                                        isEmail={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextInput
                                                        id="company"
                                                        type="text"
                                                        field="company"
                                                        value={formState.company.value}
                                                        onChange={hasFormValueChanged}
                                                        required={true}
                                                        maxLength={100}
                                                        label="Company"
                                                        placeholder=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group prospect-seelct">
                                                    <label>Prospect Type</label>
                                                    <Select
                                                        id="prospectType"
                                                        field="prospectType"
                                                        className="search-input"
                                                        type="select"
                                                        value={selectedProspect}
                                                        onChange={handleChangeProspect}
                                                        options={protoTypeSelect}
                                                        required={true}
                                                        label="company Users"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="demonstration-divider"></div>
                            <hr />
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12 demonstrationSetUp-left">
                                    <div className="">
                                        <h5>Demonstration Detail</h5>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-12 demonstrationSetUp-right">
                                    <form name="demonstrationSetUp">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group prospect-seelct">
                                                    <label>Number Allowed</label>
                                                    <Select
                                                        id="numberAllowed"
                                                        field="numberAllowed"
                                                        className="search-input"
                                                        type="select"
                                                        value={selectedProspect2}
                                                        onChange={handleChangeProspect2}
                                                        options={numberAllowedSelect}
                                                        required={true}
                                                        label="Number Allowed"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group prospect-seelct">
                                                    <label>Expiry in Days</label>
                                                    <Select
                                                        id="expiryDays"
                                                        field="expiryDays"
                                                        className="search-input"
                                                        type="select"
                                                        value={selectedProspect3}
                                                        onChange={handleChangeProspect3}
                                                        options={expiryDaysSelect}
                                                        required={true}
                                                        label="Expiry Days"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12 demonstrationSetUp-left"></div>
                                <div className="d-flex justify-content-center mt-5">
                                    <Button variant="primary" className="crate-btn-modal"
                                        disabled={isDiable}
                                        onClick={create}>
                                        SUBMIT
                                    </Button>
                                </div>
                            </div>

                        </>
                        :
                        <>
                            <div className="demonstration-heading">
                                <h2>Demonstrator </h2>
                            </div>
                            <div className="demonstration-allocate-none">
                                <p>
                                    An email has been sent to{" "}
                                    <span style={{ textTransform: "capitalize" }}>{formState.firstName.value} {" "} {formState.lastName.value} {" "}</span>

                                    with instructions on how
                                    to use the Demonstrator
                                </p>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    );
};

export default DemonstrationSetUp;
